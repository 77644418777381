import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField
} from '@mui/material';
import dayjs from 'dayjs';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { H4 } from 'app/components/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { getInvoiceAccountData } from 'app/contexts/reducers/account/accountSlice';
import { convertCustomerId, convertYearId } from 'app/utils/convertId';
import { getSingleCustomerRunningOrder } from 'app/contexts/reducers/transaction/oderSlice';
import { getBookMaster } from 'app/contexts/reducers/bookMaster/bookMasterSlice';
import CustomerRunningOrder from './CustomerRunningOrder';
import { fetchOutwardData } from '../helper/fetchOutwardDetails';
import { genrateInvoice } from 'app/contexts/reducers/transaction/invoiceSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const CreateInvoice = ({ handleApiCall, apiCall }) => {
  const dispatch = useDispatch();

  const { isInvoiceCustomerSuccess, invoiceCustomerData } = useSelector((state) => state.account);
  const { isSingleCustomerRunningOrderSuccess, singleCustomerRunningOrder } = useSelector(
    (state) => state.order
  );

  const { bookYear, isBookMasterSuccess, bookMasterData } = useSelector(
    (state) => state.bookMaster
  );
  const intialInvoiceData = {
    bookNumber: dayjs().year(),
    customerName: '',
    customerId: null,
    usedWeight: '',
    selfWeight: '',
    usedPcs: '',
    labourChargePerPcs: '',
    labourCharge: '',
    purity: '',
    diamondCaratPrice: '',
    diamondPrice: '',
    productPrice: '',
    extraCost: 0,
    mobileNumber: '',
    address: '',
    email: '',
    date: dayjs(),
    remark: '',
    quantity: '',
    subTotal: '',
    total: '',
    orders: []
  };

  const [createInvoice, setCreateInvoice] = useState(intialInvoiceData);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [accountCustomerData, setAccountCustomerData] = useState([]);
  const [customerRunningOrder, setCustomerRunningOrder] = useState([]);
  const [dataError, setDataError] = useState({
    customerNameError: false,
    customerIdError: false,
    usedPcsError: false,
    usedWeightError: false,
    labourPerPcsError: false,
    labourChargeError: false,

    quantityError: false,
    totalError: false,
    subTotalError: false
  });
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

  const [callYear, setCallYear] = useState(false);

  useEffect(() => {
    const quantity = selectedOrderDetails.length;
    (async () => {
      try {
        // Assuming 'orders' and 'dispatch' are available within the component
        const result = await fetchOutwardData(selectedOrderDetails, dispatch);
        setCreateInvoice((prevCreateOrder) => ({
          ...prevCreateOrder,
          quantity,
          usedPcs: result.piecesSum,
          selfWeight: result.weightSum,
          usedWeight: result.totalWeight,
          productPrice: result.totalProductPrice
        }));
        setDataError((prevDataError) => ({
          ...prevDataError,
          usedPcsError: false,
          usedWeightError: false,
          quantityError: false
        }));
        // Further processing with the result if needed
      } catch (error) {
        console.error('Error:', error);
      }
    })();
    // setCreateInvoice((prevCreateOrder) => ({ ...prevCreateOrder, quantity }));
  }, [selectedOrderDetails, dispatch]);
  useEffect(() => {
    dispatch(getInvoiceAccountData());

    dispatch(getBookMaster());
  }, [dispatch]);
  useEffect(() => {
    createInvoice.customerId &&
      dispatch(getSingleCustomerRunningOrder({ customerId: createInvoice.customerId }));
    setSelectedOrderDetails([]);
  }, [dispatch, createInvoice.customerId, apiCall]);
  useEffect(() => {
    const totalPieces = Number(createInvoice.usedPcs) > 0 ? Number(createInvoice.usedPcs) : 1;
    const labourCharge = totalPieces * Number(createInvoice.labourChargePerPcs);
    setCreateInvoice((prevCreateOrder) => ({ ...prevCreateOrder, labourCharge }));
    setDataError((prevDataError) => ({ ...prevDataError, labourChargeError: false }));
  }, [createInvoice.labourChargePerPcs, createInvoice.usedPcs]);

  useEffect(() => {
    const totalSelfWeight =
      Number(createInvoice.selfWeight) > 0 ? Number(createInvoice.selfWeight) : 1;
    const diamondPrice = Math.round(totalSelfWeight * Number(createInvoice.diamondCaratPrice));
    setCreateInvoice((prevCreateOrder) => ({ ...prevCreateOrder, diamondPrice }));
  }, [createInvoice.diamondCaratPrice, createInvoice.selfWeight]);

  useEffect(() => {
    const total =
      Number(createInvoice.diamondPrice) +
      Number(createInvoice.labourCharge) +
      Number(createInvoice.productPrice) +
      Number(createInvoice.extraCost);
    setCreateInvoice((prevCreateOrder) => ({ ...prevCreateOrder, total, subTotal: total }));
    setDataError((prevDataError) => ({
      ...prevDataError,
      totalError: false,
      subTotalError: false
    }));
  }, [
    createInvoice.diamondPrice,
    createInvoice.labourCharge,
    createInvoice.productPrice,
    createInvoice.extraCost
  ]);

  useEffect(() => {
    if (isSingleCustomerRunningOrderSuccess) {
      if (Number(singleCustomerRunningOrder.data.code) === 200) {
        setCustomerRunningOrder(singleCustomerRunningOrder.data.data);
      } else {
        setCustomerRunningOrder([]);
      }
    }
  }, [singleCustomerRunningOrder, isSingleCustomerRunningOrderSuccess]);
  useEffect(() => {
    isInvoiceCustomerSuccess && setAccountCustomerData(invoiceCustomerData.data.data);

    isBookMasterSuccess && getBookId();
    // eslint-disable-next-line
  }, [isInvoiceCustomerSuccess, isBookMasterSuccess, invoiceCustomerData, bookMasterData]);

  useEffect(() => {
    getBookId();
    // eslint-disable-next-line
  }, [callYear]);
  const getBookId = async () => {
    const yearId = await convertYearId(bookMasterData?.data?.data, bookYear);
    yearId && setCreateInvoice({ ...createInvoice, bookId: yearId.id });
  };
  function handleClickError() {
    setSuccessOpen(false);
    setErrorOpen(true);
  }
  function handleClickSuccess(message) {
    setErrorOpen(false);
    setSuccessMessage(message);
    setSuccessOpen(true);
  }
  function handleSnakeBarClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
  }
  const handleCustomerSelect = async (e) => {
    const customerId = await convertCustomerId(accountCustomerData, e.target.value);
    setCreateInvoice({
      ...createInvoice,
      customerId: customerId.id,
      customerName: e.target.value,
      email: customerId.email,
      address: customerId.address,
      mobileNumber: customerId.mobile_number
    });
    setDataError({ ...dataError, customerNameError: false, customerIdError: false });
  };
  const handleBookNameSelect = async (e) => {
    setCreateInvoice({ ...createInvoice, bookNumber: e.target.value });
  };
  const getFiveYearsBack = () => {
    const currentYear = new Date().getFullYear();
    const fiveYearsBackArray = [];

    for (let i = 0; i < 3; i++) {
      const year = currentYear - i;
      fiveYearsBackArray.push(String(year));
    }

    return fiveYearsBackArray;
  };

  const handleCheckboxChange = (detail) => {
    const selectedIndex = selectedOrderDetails.indexOf(detail);

    // If the item is not in the array, add it. Otherwise, remove it.
    const newSelected = [...selectedOrderDetails];

    if (selectedIndex === -1) {
      newSelected.push(detail);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setCreateInvoice({ ...createInvoice, orders: newSelected });
    setSelectedOrderDetails(newSelected);
  };
  // useEffect to update extraCost based on the number of orders
  useEffect(() => {
    setCreateInvoice((prevData) => {
      const updatedExtraCost = prevData.orders.length * 1000; // Calculate based on current length of orders
      return {
        ...prevData,
        extraCost: updatedExtraCost // Set the extraCost to the new calculated total
      };
    });
  }, [createInvoice.orders]);

  const handleSubmit = async () => {
    try {
      if (
        createInvoice.customerName &&
        createInvoice.customerName.length > 0 &&
        createInvoice.customerId &&
        Number(createInvoice.customerId) > 0 &&
        createInvoice.usedWeight &&
        createInvoice.usedPcs &&
        Number(createInvoice.labourChargePerPcs) >= 0 &&
        Number(createInvoice.labourCharge) >= 0 &&
        createInvoice.bookNumber &&
        Number(createInvoice.bookNumber) > 0 &&
        createInvoice.quantity &&
        createInvoice.total &&
        createInvoice.date &&
        createInvoice.subTotal &&
        createInvoice.orders.length > 0
      ) {
        const invoiceDetails = { ...createInvoice };
        const propertiesToConvert = [
          'usedWeight',
          'selfWeight',
          'customerId',
          'usedPcs',
          'labourChargePerPcs',
          'diamondCaratPrice',
          'diamondPrice',
          'labourCharge',
          'productPrice',
          'extraCost',
          'quantity',
          'subTotal',
          'total'
        ];
        propertiesToConvert.forEach((property) => {
          if (invoiceDetails.hasOwnProperty(property)) {
            if (property === 'usedWeight' || property === 'selfWeight') {
              invoiceDetails[property] = parseFloat(
                parseFloat(invoiceDetails[property]).toFixed(3)
              );
            } else {
              invoiceDetails[property] = parseInt(invoiceDetails[property]);
            }
          }
        });
        Object.keys(invoiceDetails).forEach((key) => {
          if (invoiceDetails[key] === '') {
            delete invoiceDetails[key];
          }
        });

        const responseValue = await dispatch(genrateInvoice(invoiceDetails));
        if (!responseValue.hasOwnProperty('error')) {
          handleClickSuccess('Invoice Genrated successfully!!');
          setCreateInvoice(intialInvoiceData);
          setCustomerRunningOrder([]);
          setLoading(false);
          handleApiCall();
          setCallYear((prev) => !prev);
        } else {
          throw responseValue;
        }
      } else {
        const validationRules = [
          {
            property: 'customerName',
            condition: createInvoice.customerName <= 0,
            errorKey: 'customerNameError'
          },
          {
            property: 'customerId',
            condition: Number(createInvoice.customerId) <= 0,
            errorKey: 'customerIdError'
          },
          {
            property: 'usedWeight',
            condition: Number(createInvoice.usedWeight) <= 0,
            errorKey: 'usedWeightError'
          },
          {
            property: 'usedPcs',
            condition: Number(createInvoice.usedPcs) <= 0,
            errorKey: 'usedPcsError'
          },
          {
            property: 'labourChargePerPcs',
            condition: Number(createInvoice.labourChargePerPcs) < 0,
            errorKey: 'labourPerPcsError'
          },
          {
            property: 'labourCharge',
            condition: Number(createInvoice.labourCharge) < 0,
            errorKey: 'labourChargeError'
          },
          {
            property: 'quantity',
            condition: Number(createInvoice.quantity) <= 0,
            errorKey: 'quantityError'
          },
          {
            property: 'total',
            condition: Number(createInvoice.total) <= 0,
            errorKey: 'totalError'
          },
          {
            property: 'subTotal',
            condition: Number(createInvoice.subTotal) <= 0,
            errorKey: 'subTotalError'
          }
        ];

        const updatedErrors = validationRules.reduce(
          (errors, rule) => {
            if (rule.condition) {
              errors[rule.errorKey] = true;
            }
            return errors;
          },
          { ...dataError }
        );

        setDataError(updatedErrors);

        handleClickError();
        setError({ payload: 'Please Fill Mandatory Data!' });
        setLoading(false);
      }
    } catch (error) {
      handleClickError();
      setError(error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {' '}
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleSnakeBarClose}>
        <Alert
          onClose={handleSnakeBarClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {error?.payload}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} sx={{ mb: '24px' }}>
        <Grid item xs={12} md={12}>
          <StyledCard elevation={6}>
            <H4 sx={{ mb: 2 }}>CREATE INVOICE</H4>
            <Grid container sx={{ marginBottom: 3 }}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  sx={{ fontSize: '10px' }}
                  error={dataError.customerNameError}
                  required
                  id='accountGroupName'
                >
                  Customer Name
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '80%', my: 1 }}
                  variant='standard'
                  labelId='accountGroupName'
                  value={createInvoice.customerName}
                  onChange={handleCustomerSelect}
                  error={dataError.customerNameError}
                >
                  {accountCustomerData.map((data, index) => (
                    <MenuItem value={data.account_name} key={index} id={index}>
                      {data.account_name}
                    </MenuItem>
                  ))}
                </Select>
                <ContentBox>
                  <TextField
                    id='usedPcs'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Used Piece'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.usedPcs}
                    error={dataError.usedPcsError}
                    helperText={dataError.usedPcsError ? 'Please Select Order First' : ''}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='labourpiececharge'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Labour/Piece Charge'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.labourChargePerPcs}
                    error={dataError.labourPerPcsError}
                    helperText={
                      dataError.labourPerPcsError ? 'Please Enter Charge Of Per Piece' : ''
                    }
                    onChange={(e) => {
                      setCreateInvoice({ ...createInvoice, labourChargePerPcs: e.target.value });
                      setDataError({ ...dataError, labourPerPcsError: false });
                    }}
                    required
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <ContentBox>
                  <TextField
                    id='customerId'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Customer Id'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.customerId}
                    error={dataError.customerIdError}
                    helperText={dataError.customerIdError ? 'Please Select Customer First' : ''}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='usedWeight'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Used Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.usedWeight}
                    error={dataError.usedWeightError}
                    helperText={dataError.usedWeightError ? 'Please Select Order First' : ''}
                  />
                </ContentBox>

                <ContentBox>
                  <TextField
                    id='labourcharge'
                    required
                    type='text'
                    margin='dense'
                    size='small'
                    label='Labour Charge'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.labourCharge}
                    error={dataError.labourChargeError}
                    helperText={dataError.labourChargeError ? 'Please Enter Labour Charge' : ''}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '80%', my: 1 }}
                    label='Date'
                    format='DD/MM/YYYY'
                    value={createInvoice.date}
                    onChange={(newValue) => setCreateInvoice({ ...createInvoice, date: newValue })}
                    slotProps={{ textField: { variant: 'standard' } }}
                  />
                </LocalizationProvider>
                <ContentBox>
                  <TextField
                    id='selfUsedWeight'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Self Used Weight'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.selfWeight}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='diamondcaratprice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Diamond/Carat Price'
                    disabled={Number(createInvoice.selfWeight) === 0 ? true : false}
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.diamondCaratPrice}
                    onChange={(e) => {
                      setCreateInvoice({ ...createInvoice, diamondCaratPrice: e.target.value });
                    }}
                  />
                </ContentBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel sx={{ fontSize: '10px' }} required id='itemName'>
                  Book Number
                </InputLabel>

                <Select
                  size='small'
                  sx={{ width: '80%', my: 1 }}
                  variant='standard'
                  labelId='itemName'
                  value={createInvoice.bookNumber}
                  onChange={handleBookNameSelect}
                >
                  {getFiveYearsBack().map((data, index) => (
                    <MenuItem value={data} key={index} id={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
                <ContentBox>
                  <TextField
                    id='productPrice'
                    type='number'
                    margin='dense'
                    size='small'
                    label='Product Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    value={createInvoice.productPrice}
                  />
                </ContentBox>
                <ContentBox>
                  <TextField
                    id='diamondPrice'
                    type='text'
                    margin='dense'
                    size='small'
                    label='Diamond Price'
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 1, width: '80%' }}
                    variant='standard'
                    required
                    value={createInvoice.diamondPrice}
                  />
                </ContentBox>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='purity'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Purity'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.purity}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, purity: e.target.value });
                      }}
                    />
                  </ContentBox>
                  <ContentBox>
                    <TextField
                      multiline
                      maxRows={4}
                      id='specialNote'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Special Note'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.remark}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, remark: e.target.value });
                      }}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='email'
                      type='email'
                      margin='dense'
                      size='small'
                      label='Email'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.email}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, email: e.target.value });
                      }}
                    />
                  </ContentBox>
                  <ContentBox>
                    <TextField
                      id='extraCost'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Extra Cost'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.extraCost}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, extraCost: e.target.value });
                      }}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      multiline
                      maxRows={4}
                      id='address'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Address'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.address}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, address: e.target.value });
                      }}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <ContentBox>
                    <TextField
                      id='mobileNumber'
                      type='tel'
                      margin='dense'
                      size='small'
                      label='Mobile Number'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='standard'
                      value={createInvoice.mobileNumber == null ? '' : createInvoice.mobileNumber}
                      onChange={(e) => {
                        setCreateInvoice({ ...createInvoice, mobileNumber: e.target.value });
                      }}
                    />
                  </ContentBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='quantity'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Quantity'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={createInvoice.quantity}
                      error={dataError.quantityError}
                      helperText={dataError.quantityError ? 'Please Select Order First' : ''}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='subtotal'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Sub Total'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={createInvoice.subTotal}
                      error={dataError.subTotalError}
                      helperText={dataError.subTotalError ? 'Please Select Order First' : ''}
                    />
                  </ContentBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <ContentBox>
                    <TextField
                      id='tota;'
                      type='text'
                      margin='dense'
                      size='small'
                      label='Total'
                      InputLabelProps={{ shrink: true }}
                      sx={{ my: 1, width: '80%' }}
                      variant='outlined'
                      value={createInvoice.total}
                      error={dataError.totalError}
                      helperText={dataError.totalError ? 'Please Select Order First' : ''}
                    />
                  </ContentBox>
                </Grid>
              </Grid>
            </Grid>
            {Number(createInvoice.customerId) > 0 && customerRunningOrder.length > 0 && (
              <CustomerRunningOrder
                customerRunningOrder={customerRunningOrder}
                handleCheckboxChange={handleCheckboxChange}
                selectedOrderDetails={selectedOrderDetails}
                setSelectedOrderDetails={setSelectedOrderDetails}
              />
            )}
            <LoadingButton
              onClick={handleSubmit}
              color='primary'
              loading={loading}
              variant='contained'
              sx={{ my: 2, textTransform: 'capitalize' }}
            >
              Create Invoice
            </LoadingButton>
          </StyledCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateInvoice;
